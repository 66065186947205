<template>
  <div>
    <a-modal v-model="work_visible" title="工作记录" width="800px" :footer="null" @cancel="onCloseModel">
      <a-row gutter="16">
        <a-col :span="24" style="width: 256px;">
          <a-input v-model="searchForm.search" placeholder="工作名称, 编码, 条码" allowClear @pressEnter="search" />
        </a-col>
        <a-space>
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-space>

        <a-space style="margin-left: 10px;">
          <a-button type="primary" icon="plus" @click="add_workload">添加记录</a-button>
        </a-space>
      </a-row>

      <div style="margin-top: 12px;">
        <a-table :data-source="items" rowKey="id" :columns="columns" :loading="loading" :pagination="pagination"
          @change="onChangeTable">
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button icon="edit" size="small" @click="openFormModal(item)">编辑</a-button>
              <a-popconfirm title="确定删除吗" @confirm="destroy(item.id)">
                <a-button type="danger" icon="delete" size="small">删除</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </div>
    </a-modal>

    <workload-form
      v-model="form_visible"
      :process_items ="process_items"
      :task_form="task_form"
      :form="form"
      @create="create"
      @update="update" />
      />
  

  </div>
</template>

<script>
  import { columns } from './columns';
  import { TaskProcessList,TaskProcessDestroy} from '@/api/production'


  export default {
    name: 'Workload',
    components: {
      WorkloadForm: () => import("@/components/Workload/formModal"),
    },
    props: ['work_visible', 'task_form','process_items'],
    model: { prop: 'work_visible', event: 'cancel' },
    data() {
      return {
        columns,
        pagination: {},
        searchForm: { search: '' },
        loading: false,
        items: [],
        form:{},
        form_visible: false,
      }
    },
    methods: {
      onCloseModel() {
        this.$emit('cancel', false);
      },

      openFormModal(item){
        console.log('demo',item)
        if (item.id) {
          this.form = {...item}
        }
        // console.log('this.form',this.form)
        this.form_visible = true
      },

      destroy(id) {
        TaskProcessDestroy({ id }).then(() => {
          // this.items.splice(this.items.findIndex(item => item.id == id), 1);
          this.$message.success('删除成功');
          this.list();
        });
      },

      onChangeTable(pagination, filters, sorter) {
        this.pagination = pagination;
        this.searchForm.page = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },
      search() {
        this.pagination.current = 1;
        this.searchForm.page = 1;
        this.searchForm.production_task = this.task_form.id;
        this.list();
      },
      create(item) {
        // this.items.splice(0, 0, item);
        this.list();
      },
      update(item) {
        console.log('更新')
        this.items.splice(this.items.findIndex(i => i.id == item.id), 1, item);
      },
      add_workload(){
        console.log('add_workload')
        this.form = {}
        this.form_visible = true
      },
      list() {
        this.loading = true;
        TaskProcessList(this.searchForm).then(data => {
          this.pagination.total = data.count;
          this.items = data.results;
        }).finally(() => {
          this.loading = false;
        });
      },
      select(item) {
        this.$emit('select', item);
        this.onCloseModel();
      },
      resetModel() {
        this.pagination = { current: 1, total: 0, pageSize: 16, showTotal: total => `共 ${total} 条` };
        this.searchForm = { page: 1, warehouse: this.warehouse, is_active: true };
        this.items = [];
      },
    },
    watch: {
      visible(status) {
        if (status) {
          this.resetModel();
          this.list();
        }
      },
    },
  }
</script>