export const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    customRender: (value, item, index) => {
      return index + 1
    },
  },
  {
    title: '工人姓名',
    dataIndex: 'worker_name',
    key: 'worker_name',
  },
  {
    title: '流程',
    dataIndex: 'process_name',
    key: 'process_name',
  },
  {
    title: '完成数量',
    dataIndex: 'quantity_done',
    key: 'quantity_done',
  },
  {
    title: '添加日期',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: '添加人',
    dataIndex: 'handler_name',
    key: 'handler_name',
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]